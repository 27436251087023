<template>
    <div class="bar-placeholder"></div>
</template>

<style scoped>
.bar-placeholder {
    height: 20vh;
}
</style>

<script>
/**
 * Component to create a free space at the bottom.
 */

export default {
    name: "Placeholder"
};
</script>
